var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item md-small-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-green" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("mail_outline")])],
                  1
                ),
                _c("h4", { staticClass: "title" }, [_vm._v("Stacked Form")]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _c(
                  "md-field",
                  [
                    _c("label", [_vm._v("Email Adress")]),
                    _c("md-input", {
                      attrs: { type: "email" },
                      model: {
                        value: _vm.emailadress,
                        callback: function ($$v) {
                          _vm.emailadress = $$v
                        },
                        expression: "emailadress",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "md-field",
                  [
                    _c("label", [_vm._v("Password")]),
                    _c("md-input", {
                      attrs: { type: "password" },
                      model: {
                        value: _vm.password,
                        callback: function ($$v) {
                          _vm.password = $$v
                        },
                        expression: "password",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "md-checkbox",
                  {
                    model: {
                      value: _vm.boolean,
                      callback: function ($$v) {
                        _vm.boolean = $$v
                      },
                      expression: "boolean",
                    },
                  },
                  [_vm._v("Subscribe to newsletter")]
                ),
              ],
              1
            ),
            _c(
              "md-card-actions",
              { attrs: { "md-alignment": "left" } },
              [
                _c("md-button", { staticClass: "md-success" }, [
                  _vm._v("Submit"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-small-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-green" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("contacts")])],
                  1
                ),
                _c("h4", { staticClass: "title" }, [_vm._v("Horizontal Form")]),
              ]
            ),
            _c("md-card-content", [
              _c(
                "form",
                {
                  staticClass: "form-horizontal",
                  attrs: { action: "", method: "post" },
                },
                [
                  _c("div", { staticClass: "md-layout" }, [
                    _c(
                      "label",
                      {
                        staticClass: "md-layout-item md-size-25 md-form-label",
                      },
                      [_vm._v(" Email Adress ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "md-layout-item" },
                      [
                        _c(
                          "md-field",
                          [
                            _c("md-input", {
                              attrs: { type: "email" },
                              model: {
                                value: _vm.emailadress1,
                                callback: function ($$v) {
                                  _vm.emailadress1 = $$v
                                },
                                expression: "emailadress1",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "md-layout" }, [
                    _c(
                      "label",
                      {
                        staticClass: "md-layout-item md-size-25 md-form-label",
                      },
                      [_vm._v(" Password ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "md-layout-item" },
                      [
                        _c(
                          "md-field",
                          [
                            _c("md-input", {
                              attrs: { type: "password" },
                              model: {
                                value: _vm.password1,
                                callback: function ($$v) {
                                  _vm.password1 = $$v
                                },
                                expression: "password1",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "md-layout" }, [
                    _c("label", {
                      staticClass: "md-layout-item md-size-25 md-form-label",
                    }),
                    _c(
                      "div",
                      { staticClass: "md-layout-item" },
                      [
                        _c(
                          "md-checkbox",
                          {
                            model: {
                              value: _vm.boolean1,
                              callback: function ($$v) {
                                _vm.boolean1 = $$v
                              },
                              expression: "boolean1",
                            },
                          },
                          [_vm._v("Remember Me")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
            _c(
              "md-card-actions",
              { attrs: { "md-alignment": "left" } },
              [
                _c("md-button", { staticClass: "md-success" }, [
                  _vm._v("Sign In"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-text md-card-header-green" },
              [
                _c("div", { staticClass: "card-text" }, [
                  _c("h4", { staticClass: "title" }, [_vm._v("Form Elements")]),
                ]),
              ]
            ),
            _c("md-card-content", [
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "label",
                  { staticClass: "md-layout-item md-size-15 md-form-label" },
                  [_vm._v(" With Help ")]
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-field",
                      [
                        _c("md-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.helper,
                            callback: function ($$v) {
                              _vm.helper = $$v
                            },
                            expression: "helper",
                          },
                        }),
                        _c("span", { staticClass: "md-helper-text" }, [
                          _vm._v(
                            "A block of help text that breaks onto a new line."
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "label",
                  { staticClass: "md-layout-item md-size-15 md-form-label" },
                  [_vm._v(" Password ")]
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-field",
                      [
                        _c("md-input", {
                          attrs: { type: "password" },
                          model: {
                            value: _vm.password2,
                            callback: function ($$v) {
                              _vm.password2 = $$v
                            },
                            expression: "password2",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "label",
                  { staticClass: "md-layout-item md-size-15 md-form-label" },
                  [_vm._v(" Placeholder ")]
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-field",
                      [
                        _c("label", [_vm._v("With label")]),
                        _c("md-input", {
                          attrs: { placeholder: "placeholder" },
                          model: {
                            value: _vm.placeholder,
                            callback: function ($$v) {
                              _vm.placeholder = $$v
                            },
                            expression: "placeholder",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "label",
                  { staticClass: "md-layout-item md-size-15 md-form-label" },
                  [_vm._v(" Disabled ")]
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-field",
                      [
                        _c("label", [_vm._v("Disabled input here..")]),
                        _c("md-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.disabled,
                            callback: function ($$v) {
                              _vm.disabled = $$v
                            },
                            expression: "disabled",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "label",
                  { staticClass: "md-layout-item md-size-15 md-form-label" },
                  [_vm._v(" Static Control ")]
                ),
                _c("div", { staticClass: "md-layout-item" }, [
                  _c("p", { staticClass: "form-control-static" }, [
                    _vm._v("hello@creative-tim.com"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "label",
                  { staticClass: "md-layout-item md-size-15 md-form-label" },
                  [_vm._v(" Checkboxes and Radios ")]
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.checkbox1,
                          callback: function ($$v) {
                            _vm.checkbox1 = $$v
                          },
                          expression: "checkbox1",
                        },
                      },
                      [_vm._v("First Checkbox")]
                    ),
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.checkbox2,
                          callback: function ($$v) {
                            _vm.checkbox2 = $$v
                          },
                          expression: "checkbox2",
                        },
                      },
                      [_vm._v("Second Checkbox")]
                    ),
                    _c(
                      "md-radio",
                      {
                        attrs: { value: false },
                        model: {
                          value: _vm.radio,
                          callback: function ($$v) {
                            _vm.radio = $$v
                          },
                          expression: "radio",
                        },
                      },
                      [_vm._v("First Radio")]
                    ),
                    _c(
                      "md-radio",
                      {
                        attrs: { value: true },
                        model: {
                          value: _vm.radio,
                          callback: function ($$v) {
                            _vm.radio = $$v
                          },
                          expression: "radio",
                        },
                      },
                      [_vm._v("Second Radio")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "label",
                  { staticClass: "md-layout-item md-size-15 md-form-label" },
                  [_vm._v(" Inline Checkboxes ")]
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item md-inline-checkboxes" },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.checkbox3,
                          callback: function ($$v) {
                            _vm.checkbox3 = $$v
                          },
                          expression: "checkbox3",
                        },
                      },
                      [_vm._v("a")]
                    ),
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.checkbox4,
                          callback: function ($$v) {
                            _vm.checkbox4 = $$v
                          },
                          expression: "checkbox4",
                        },
                      },
                      [_vm._v("b")]
                    ),
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.checkbox5,
                          callback: function ($$v) {
                            _vm.checkbox5 = $$v
                          },
                          expression: "checkbox5",
                        },
                      },
                      [_vm._v("c")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-text md-card-header-green" },
              [
                _c("div", { staticClass: "card-text" }, [
                  _c("h4", { staticClass: "title" }, [
                    _vm._v("Input Variants"),
                  ]),
                ]),
              ]
            ),
            _c("md-card-content", [
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "label",
                  { staticClass: "md-layout-item md-size-15 md-form-label" },
                  [_vm._v(" Custom Checkboxes & Radios ")]
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.checkbox6,
                          callback: function ($$v) {
                            _vm.checkbox6 = $$v
                          },
                          expression: "checkbox6",
                        },
                      },
                      [_vm._v("Checked")]
                    ),
                    _c(
                      "md-checkbox",
                      {
                        model: {
                          value: _vm.checkbox7,
                          callback: function ($$v) {
                            _vm.checkbox7 = $$v
                          },
                          expression: "checkbox7",
                        },
                      },
                      [_vm._v("Unchecked")]
                    ),
                    _c(
                      "md-checkbox",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.checkbox8,
                          callback: function ($$v) {
                            _vm.checkbox8 = $$v
                          },
                          expression: "checkbox8",
                        },
                      },
                      [_vm._v("Disabled Checked")]
                    ),
                    _c(
                      "md-checkbox",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.checkbox9,
                          callback: function ($$v) {
                            _vm.checkbox9 = $$v
                          },
                          expression: "checkbox9",
                        },
                      },
                      [_vm._v("Disabled Unchecked")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-radio",
                      {
                        attrs: { value: true },
                        model: {
                          value: _vm.radio1,
                          callback: function ($$v) {
                            _vm.radio1 = $$v
                          },
                          expression: "radio1",
                        },
                      },
                      [_vm._v("Radio is on")]
                    ),
                    _c(
                      "md-radio",
                      {
                        attrs: { value: false },
                        model: {
                          value: _vm.radio1,
                          callback: function ($$v) {
                            _vm.radio1 = $$v
                          },
                          expression: "radio1",
                        },
                      },
                      [_vm._v("Radio is off")]
                    ),
                    _c(
                      "md-radio",
                      {
                        attrs: { value: true, disabled: "" },
                        model: {
                          value: _vm.radio2,
                          callback: function ($$v) {
                            _vm.radio2 = $$v
                          },
                          expression: "radio2",
                        },
                      },
                      [_vm._v("Disabled Radio is on")]
                    ),
                    _c(
                      "md-radio",
                      {
                        attrs: { value: false, disabled: "" },
                        model: {
                          value: _vm.radio2,
                          callback: function ($$v) {
                            _vm.radio2 = $$v
                          },
                          expression: "radio2",
                        },
                      },
                      [_vm._v("Disabled Radio is off")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "label",
                  { staticClass: "md-layout-item md-size-15 md-form-label" },
                  [_vm._v(" Input with success ")]
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-field",
                      { staticClass: "md-valid" },
                      [
                        _c("label", [_vm._v("Success Input")]),
                        _c("md-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.success,
                            callback: function ($$v) {
                              _vm.success = $$v
                            },
                            expression: "success",
                          },
                        }),
                        _c("md-icon", [_vm._v("done")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "label",
                  { staticClass: "md-layout-item md-size-15 md-form-label" },
                  [_vm._v(" Input with error ")]
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item" },
                  [
                    _c(
                      "md-field",
                      { staticClass: "md-error" },
                      [
                        _c("label", [_vm._v("Error Input")]),
                        _c("md-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.error,
                            callback: function ($$v) {
                              _vm.error = $$v
                            },
                            expression: "error",
                          },
                        }),
                        _c("md-icon", [_vm._v("clear")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "label",
                  { staticClass: "md-layout-item md-size-15 md-form-label" },
                  [_vm._v(" Column sizing ")]
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item md-size-20" },
                  [
                    _c(
                      "md-field",
                      [
                        _c("label", [_vm._v(".md-size-20")]),
                        _c("md-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.layout1,
                            callback: function ($$v) {
                              _vm.layout1 = $$v
                            },
                            expression: "layout1",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item md-size-25" },
                  [
                    _c(
                      "md-field",
                      [
                        _c("label", [_vm._v(".md-size-25")]),
                        _c("md-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.layout2,
                            callback: function ($$v) {
                              _vm.layout2 = $$v
                            },
                            expression: "layout2",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "md-layout-item md-size-40" },
                  [
                    _c(
                      "md-field",
                      [
                        _c("label", [_vm._v(".md-size-40")]),
                        _c("md-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.layout3,
                            callback: function ($$v) {
                              _vm.layout3 = $$v
                            },
                            expression: "layout3",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }